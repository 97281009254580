import React from 'react';

import styles from './ListThumbnailMissingImage.module.scss';
import iconHeart from '@ingka/ssr-icon/paths/heart';
import { colourTextAndIcon3 } from '@ingka/variables/colours-css';
import Text from '@skapa/Text';
import { clsx } from 'clsx';
import useTranslations from 'context/Translations';

import SSRIcon from 'skapa/SSRIcon';

const ListThumbnailMissingImage = ({
    smallDevice = false,
    standAlone = false,
}) => {
    const translate = useTranslations();
    const showText = standAlone || !smallDevice;

    return (
        <div className={clsx(styles.wrapper, standAlone && styles.standAlone)}>
            <SSRIcon color={colourTextAndIcon3} paths={iconHeart} />
            {showText && (
                <Text bodySize="m" tagName="p" className={styles.content}>
                    {translate('LISTOVERVIEW_LISTS_THUMBNAIL_EMPTY')}
                </Text>
            )}
        </div>
    );
};

export default ListThumbnailMissingImage;

import React from 'react';

import LandingPage from '@components/LandingPage';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import { prefetchTranslations } from '@utils/prefetchTranslations';
import { GetStaticPaths, GetStaticProps } from 'next';

import Grid from 'skapa/Grid';

const LandingPageWrapper: React.FunctionComponent = () => {
    return (
        <Grid>
            <LandingPage />
        </Grid>
    );
};

export const getStaticProps: GetStaticProps = async ctx => {
    const queryClient = new QueryClient();
    await prefetchTranslations(ctx, queryClient);
    return {
        props: {
            dehydratedState: dehydrate(queryClient),
        },
        revalidate: 300,
    };
};

export const getStaticPaths: GetStaticPaths = async () => {
    return {
        paths: [],
        fallback: 'blocking',
    };
};

export default LandingPageWrapper;

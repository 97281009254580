import React, { useMemo } from 'react';

import CreateListBlock from './CreateListBlock';
import styles from './ListOverview.module.scss';
import ListThumbnail from './ListThumbnails';
import ListThumbnailSkeleton from './ListThumbnails/ListThumbnailSkeleton';
import { useModal } from '@components/Modals/useModal';
import { sortLists } from 'lib/client/sortLists/sortLists';

import useGetLists from 'apis/list/hooks/useGetLists';

import { useSettings } from 'hooks/useSettings';

export function ListOverview() {
    const { showModal } = useModal();
    const { data: { lists = [] } = {}, isLoading } = useGetLists({
        select: data => ({
            lists: sortLists(data?.lists),
        }),
    });
    const { maxLists } = useSettings();

    const { firstList, otherLists } = useMemo(
        () => ({
            firstList: lists[0],
            otherLists: lists.slice(1),
        }),
        [lists]
    );

    return (
        <>
            <div className={styles.topGrid}>
                <CreateListBlock
                    disabled={lists.length >= maxLists}
                    loading={isLoading}
                    onCreateList={() => showModal('CreateListModal', {})}
                />

                {isLoading ? (
                    <ListThumbnailSkeleton standAlone />
                ) : (
                    firstList && <ListThumbnail list={firstList} standAlone />
                )}
            </div>
            <div>
                {isLoading ? (
                    <ListThumbnailSkeleton />
                ) : (
                    <div className={styles.grid}>
                        {otherLists.map(list => (
                            <ListThumbnail key={list.listId} list={list} />
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

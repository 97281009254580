import React from 'react';

import styles from './ListThumbnailSkeleton.module.scss';
import { space150 } from '@ingka/variables';
import { breakpoint as Breakpoint, useBreakpoint } from '@ingka/viewport-hooks';
import Skeleton from '@skapa/Skeleton';
import { clsx } from 'clsx';

type Props = {
    standAlone?: boolean;
};

const getMaxNumberOfItems = (bp?: Breakpoint, standAlone = false) => {
    if (standAlone) {
        return 1;
    }
    switch (bp) {
        case 'sm':
            return 2;
        case 'md':
            return 3;
        default:
            return 4;
    }
};

const ListThumbnailSkeleton: React.FC<Props> = ({ standAlone }) => {
    const breakpoint = useBreakpoint('sm', 'md', 'lg', 'xl');
    return (
        <div className={clsx(styles.wrapper, standAlone && styles.standAlone)}>
            {new Array(getMaxNumberOfItems(breakpoint, standAlone))
                .fill(0)
                .map((_, i) => (
                    <div className={styles.item} key={i}>
                        <div className={styles.images}>
                            <div className={styles.left}>
                                <Skeleton width="100%" height="100%" />
                            </div>
                            <div className={styles.right}>
                                <Skeleton width="100%" height="100%" />
                                <Skeleton width="100%" height="100%" />
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <Skeleton width="50%" height={space150} />
                            <Skeleton width="25%" height={space150} />
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ListThumbnailSkeleton;

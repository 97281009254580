import useLocale from './useLocale';
import { isMobileDevice } from '@utils/isDevice';
import useUserPreferences from 'context/UserPreferences';

const useIsCustomerInStore = () => {
    const { postalCodes } = useLocale();
    const { preferredPostalCode } = useUserPreferences();
    const isCustomerInStore =
        postalCodes &&
        preferredPostalCode &&
        postalCodes.includes(Object.values(preferredPostalCode)[0]) &&
        isMobileDevice();

    return isCustomerInStore;
};

export default useIsCustomerInStore;

import React from 'react';

import styles from './ListThumbnailImages.module.scss';
import ListThumbnailMissingImage from './ListThumbnailMissingImage';
import { clsx } from 'clsx';

import Image from 'skapa/Image';

import CountBadge from 'components/CountBadge';

type ListThumbnailImagesProps = {
    items: { images: { url: string }[]; alt: string }[];
    smallDevice: boolean;
    standAlone: boolean;
};

const ListThumbnailImages: React.FC<ListThumbnailImagesProps> = ({
    smallDevice,
    items,
    standAlone,
}) => {
    const itemsCount = items.length || 0;
    const showAdditionalImage = itemsCount === 2 || standAlone || !smallDevice;

    const firstImageUrl =
        items?.[items?.length - 1]?.images?.[2]?.url ||
        items?.[items?.length - 1]?.images?.[1]?.url ||
        items?.[items?.length - 1]?.images?.[0]?.url;

    return (
        <div
            className={clsx(styles.imagesContainer, {
                [styles.standAlone]: standAlone,
                [styles.noItems]: itemsCount === 0,
                [styles.singleItem]: itemsCount === 1,
                [styles.theme]: itemsCount < 2,
            })}
        >
            <div className={styles.imageWrapper}>
                {itemsCount === 0 ? (
                    <ListThumbnailMissingImage
                        smallDevice={smallDevice}
                        standAlone={standAlone}
                    />
                ) : (
                    <Image
                        src={firstImageUrl}
                        alt={items?.[items?.length - 1]?.alt}
                    />
                )}
            </div>
            {itemsCount > 1 && (
                <div
                    className={clsx(styles.rightColumn, {
                        [styles.theme]: itemsCount === 2 && !smallDevice,
                    })}
                >
                    {showAdditionalImage && (
                        <div className={styles.imageWrapper}>
                            <Image
                                src={
                                    items?.[items?.length - 2]?.images?.[2]?.url
                                }
                                alt={items?.[items?.length - 2]?.alt}
                            />
                        </div>
                    )}
                    {itemsCount > 2 && (
                        <div className={styles.imageWrapper}>
                            <CountBadge
                                count={itemsCount - (smallDevice ? 1 : 2)}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ListThumbnailImages;

import useIsoDateToLocalFormat from './useIsoDateToLocalFormat';
import useTranslations from 'context/Translations';

const isToday = (someDate: Date, currentDate = new Date()) => {
    return (
        someDate.getDate() == currentDate.getDate() &&
        someDate.getMonth() == currentDate.getMonth() &&
        someDate.getFullYear() == currentDate.getFullYear()
    );
};

const isHour = (someDate: Date, currentDate = new Date()) => {
    return someDate.getHours() == currentDate.getHours();
};

const minutesAgo = (someDate: Date, currentDate = new Date()) => {
    return someDate.getMinutes() == currentDate.getMinutes()
        ? 0
        : currentDate.getMinutes() - someDate.getMinutes();
};

const useFormatDateText = () => {
    const isoDateToLocalFormat = useIsoDateToLocalFormat();
    const translate = useTranslations();

    const formatDateText = (isoDate: string, currentDate = new Date()) => {
        const someDate = new Date(isoDate);
        if (isToday(someDate, currentDate)) {
            if (isHour(someDate, currentDate)) {
                const numOfMinutesAgo = minutesAgo(someDate, currentDate);
                if (numOfMinutesAgo === 0 || numOfMinutesAgo === 1) {
                    return translate(
                        'LISTOVERVIEW_LISTS_THUMBNAIL_ACTIVE_UPDATED_NOW'
                    );
                } else {
                    if (numOfMinutesAgo < 60) {
                        return translate(
                            'LISTOVERVIEW_LISTS_THUMBNAIL_ACTIVE_UPDATED_MIN2',
                            { timeStamp: `${numOfMinutesAgo}` }
                        );
                    }
                }
            }

            return translate('LISTOVERVIEW_LISTS_THUMBNAIL_ACTIVE_UPDATED', {
                timeStamp: isoDateToLocalFormat(isoDate, {
                    hour: 'numeric',
                    minute: 'numeric',
                }),
            });
        }
        return translate('LISTOVERVIEW_LISTS_THUMBNAIL_ACTIVE_UPDATED', {
            timeStamp: isoDateToLocalFormat(isoDate),
        });
    };
    return formatDateText;
};

export default useFormatDateText;

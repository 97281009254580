import React, { PropsWithChildren } from 'react';

import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import useLocale from 'hooks/useLocale';

interface LinkProps extends Omit<NextLinkProps, 'as' | 'href'> {
    href: string;
}

const Link = ({
    children,
    href,
    ...restProps
}: PropsWithChildren<LinkProps>) => {
    const { appPath } = useLocale();
    return (
        <NextLink
            href={`${appPath}/${href}`}
            as={`${appPath}/${href}`}
            legacyBehavior
            {...restProps}
        >
            {children}
        </NextLink>
    );
};

export default Link;

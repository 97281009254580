import React from 'react';

import styles from './LandingPage.module.scss';
import { ListOverview } from '@components/ListOverview';
import useRunOnce from '@hooks/useRunOnce';
import { useOptimizely } from '@utils/optimizely/useOptimizely';
import dynamic from 'next/dynamic';

const Recommendations = dynamic(
    () => import('@components/RecommendationsFeed/RecommendationsFeed'),
    {
        ssr: false,
    }
);

const LandingPage = () => {
    const { decide } = useOptimizely();

    useRunOnce({
        callBack: () => {
            // Optimizely guardrail baseline dashboard
            decide('favourites_baseline');
        },
        dependencies: [decide],
    });

    return (
        <div className={styles.wrapper}>
            <ListOverview />
            <Recommendations />
        </div>
    );
};
export default LandingPage;

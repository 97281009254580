import { DependencyList, useEffect, useRef } from 'react';

type UseRunOnceOptions = {
    callBack: () => void;
    dependencies?: DependencyList;
    condition?: boolean;
};

const useRunOnce = ({
    callBack,
    dependencies = [],
    condition = true,
}: UseRunOnceOptions) => {
    const hasRun = useRef(false);
    useEffect(() => {
        if (condition && !hasRun.current) {
            hasRun.current = true;
            callBack();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...dependencies, condition, callBack]);
};

export default useRunOnce;

import React, { RefObject } from 'react';

import IngkaButton, { ButtonProps } from '@ingka/button';

const Button = (
    props: ButtonProps & {
        ref?: RefObject<HTMLAnchorElement | HTMLButtonElement>;
    }
) => <IngkaButton prefix="list-ingka-" {...props} />;

Button.displayName = IngkaButton.displayName; //

export default Button;

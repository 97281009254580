/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import styles from './ListThumbnail.module.scss';
import ListThumbnailFooter from './ListThumbnailFooter';
import ListThumbnailImages from './ListThumbnailImages';
import Link from '@components/Link';
import useIsCustomerInStore from '@hooks/useIsCustomerInStore';
import { useBreakpoint } from '@ingka/viewport-hooks';
import { clsx } from 'clsx';

import { SourceList } from 'apis/types';

type ListThumbnailProps = {
    list: SourceList;
    standAlone?: boolean;
};
const ListThumbnail: React.FC<ListThumbnailProps> = ({
    list,
    standAlone = false,
}) => {
    const breakpoint = useBreakpoint('sm', 'md', 'lg', 'xl');
    const isCustomerInStore = useIsCustomerInStore();
    const linkHref = isCustomerInStore
        ? `${list.listId}?view=store`
        : `${list.listId}?view=retail`;
    const itemImages = list.items.map(item => ({
        images: item.product?.images || [],
        alt: item.product?.name || '',
    }));

    const designImages = list.designs.map(design => ({
        images: design.images || [],
        alt: design.configurationId || '',
    }));
    return (
        <Link href={linkHref}>
            <a className={styles.noTextDecoration}>
                <div
                    className={clsx(
                        styles.wrapper,
                        standAlone && styles.standAlone
                    )}
                    data-testid="select-list-item"
                >
                    <ListThumbnailImages
                        smallDevice={breakpoint === 'sm'}
                        items={[...itemImages, ...designImages]}
                        standAlone={standAlone}
                    />
                    <ListThumbnailFooter
                        list={list}
                        standAlone={standAlone}
                        smallDevice={breakpoint === 'sm'}
                    />
                </div>
            </a>
        </Link>
    );
};

export default ListThumbnail;

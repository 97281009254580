interface SortableList {
    listId: string;
    isSelected: boolean;
    updated: string; // ISO timestamp
}

/**
 * Sorts lists with the selected list first,
 * followed by the rest in descending order according to the 'updated' timestamp.
 * */
export const sortLists = <T extends SortableList>([...lists]: T[] = []) =>
    lists.sort((a, b) => {
        if (a.isSelected && !b.isSelected) {
            return -1;
        }
        if (b.isSelected && !a.isSelected) {
            return 1;
        }
        return a.updated < b.updated ? 1 : -1;
    });

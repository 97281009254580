import useLocale from './useLocale';

const useIsoDateToLocalFormat = () => {
    const { locale } = useLocale();
    const isoDateToLocalFormat = (
        isoDate: string,
        options?: Intl.DateTimeFormatOptions
    ) => {
        const DateFormatter = new Intl.DateTimeFormat(locale, options);
        const date = new Date(isoDate);
        return DateFormatter.format(date);
    };
    return isoDateToLocalFormat;
};

export default useIsoDateToLocalFormat;

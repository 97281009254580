import React from 'react';

import VisualPlaceholder from '@components/VisualPlaceholder/VisualPlaceholder';
import IngkaImage, { ImageProps } from '@ingka/image';

const Image = (props: ImageProps) => {
    const { src, className } = props;

    if (src) {
        return <IngkaImage prefix="list-ingka-" {...props} />;
    } else {
        return <VisualPlaceholder className={className} />;
    }
};

export default Image;

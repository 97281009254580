import React from 'react';

import styles from './CountBadge.module.scss';
import Text from '@skapa/Text';

type Props = { count: number };

const CountBadge: React.FC<Props> = ({ count }) => {
    return (
        <Text headingSize="xs" className={styles.countBadge}>
            {count}
        </Text>
    );
};

export default CountBadge;
